const translations = {
  english:{
    translation: {
    }
  },
  french:{
    translation: {
    },
  }
}

const levelsList = {
  info:   { color:'primary' },
  error:  { color:'danger' },
  warn:   { color:'warning' },
  debug:  { color:'neutral' },
}

const fromsList = {
  'apid2':  { color:'primary' },
  'crond2': { color:'primary' },
  'cicd': { color:'primary' },
}

function getColor(c) {
  const colorName = c?.includes('--') ? c : c + '-700';
  return `var(--sl-color-${colorName})`;
}

const levels = {};
Object.keys(levelsList).forEach(key => {
  levels[key] = levelsList[key];
  //events[key].iconColor = getColor(list[key].color);
});

const froms = {};
Object.keys(fromsList).forEach(key => {
  froms[key] = fromsList[key];
  //froms[key].color = getColor(fromsList[key].color);
});

export default {
  levels,
  froms,
  translations,
  apiEndpoint:'private/admin/system/logs',
  urlVar:'idl'
};