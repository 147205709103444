import { PageElement, html, css } from 'Elements';

class MongoDBPage extends PageElement {

  static get styles() {
    return [
      super.styles,
      css`
        iframe {
          width: 100%;
          height: 80vh;
          border: none;
        }
      `
    ]
  }

  constructor() {
    super();
    this.layout = 'large';
  }

  openWin() {
    window.open('/mongodb/', '_blank');
  }

  render() {
    return html`
      <section-header backroute="../../" micon="database">
        MongoDB
        <m-icon name="open_in_new" clickable slot="right" @click=${this.openWin}></m-icon>
      </section-header>
      <br/>
      <iframe frameborder="0" src="/mongodb/"></iframe>
    `;
  }

}

customElements.define('page-sys-srv-mongodb', MongoDBPage);