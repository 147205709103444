import { html, css } from 'Elements';
import DrawerForm from 'Components/abstract/DrawerForm.js';
import { Lang } from 'Utils';

class AdminLanguageEdit extends DrawerForm {
  
  static get styles() {
    return [
      super.styles,
      css`
        box-styled {
          font-size:0.9em;
        }
      `
    ];
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            cancel:'Cancel',
            add:'Add a language',
            edit:'Edit',
            save:'Save',
            language:'Language',
            name:'Name',
            flag:'Flag',
            lang:'Identifier',
            iso:'Country code',
            enable:'Enabled'
          },
        },
        french:{
          translation: {
            cancel:'Annuler',
            add:'Ajouter une langue',
            edit:'Modifier',
            save:'Enregistrer',
            language:'Langue',
            lang:'Identifiant',
            name:'Nom',
            iso:'Code pays',
            flag:'Drapeau',
            enable:'Activée'
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.apiEndpoint = 'private/admin/system/languages';
    this.urlVar = 'idl';
    this.itemTitleField = 'title';
    this.eventUpdated = 'language-updated';
    this.getTranslatePayload = this.getTranslatePayload.bind(this);
    this._logEnable = false;
  }

  getTranslatePayload() {
    const payload = {
      title: this._lookupField('title').value
    }
    return payload;
  }

  onTranslated(ev) {
    this._lookupField('title', 'en').value = ev.detail.title;
  }

  handleSubmitError(input, response) {
    if (!input) {
      console.warn('Field not found', response.field);
      return;
    }

    let msg = 'Valeur obligatoire';
    if (response.reason === 'MIN_LENGTH') {
      msg = `Longueur minimum de ${response.minValue} caractères`;
    }

    if (input.setCustomValidity) input.setCustomValidity(msg);
    if (input.reportValidity) input.reportValidity();
  }

  render() {    
    if (!this.item) return '';

    return html`
      <modal-drawer name="modal-tag-edit" @close=${this.onClose}>
        <style>${this.constructor.styles}</style>
        <form>
          <tab-group level="tl" class="main">
            <sl-tab slot="nav" panel="text">${this._tl('language')}</sl-tab>

            <sl-tab-panel name="text">
              <tab-group level="tlt">
                <sl-tab slot="nav" panel="french">🇫🇷</sl-tab>
                <sl-tab slot="nav" panel="english">🇺🇸</sl-tab>

                <sl-tab-panel name="french">
                  <sl-input class="labol" size="small" name="title" lg="fr" defaultValue="" value="${Lang.lookup(this.item, 'title', 'fr')}" label="${this._tl('name')}"></sl-input><br/>
                </sl-tab-panel>

                <sl-tab-panel name="english">
                  <div class="input_with_button">
                    <sl-input class="labol" size="small" name="title" lg="en" defaultValue="" value="${Lang.lookup(this.item, 'title', 'en')}" label="${this._tl('name')}"></sl-input>
                    <button-translate .getPayload=${this.getTranslatePayload} @translated=${this.onTranslated}></button-translate>
                  </div>
                </sl-tab-panel>
              </tab-group>

              <sl-input class="labol" size="small" name="lang" defaultValue="" value="${this.item.lang}" label="${this._tl('lang')}"></sl-input><br/>
              <sl-input class="labol" size="small" name="lg" minlength="2" maxlength="2" defaultValue="" value="${this.item.lg}" label="${this._tl('iso')}"></sl-input><br/>
              <sl-input class="labol" size="small" name="flag" defaultValue="" value="${this.item.flag}" label="${this._tl('flag')}"></sl-input><br/>
              <sl-switch class="labol" name="enable" size="small" defaultValue="true" ?checked="${this.item.enable}">${this._tl('enable')}</sl-switch>

              <br/><br/>

            </sl-tab-panel>
          </tab-group>
        </form>

        <sl-button slot="bt1" variant="primary" @click="${this.handleSubmit}">${this._tl('save')}</sl-button>
        <sl-button slot="bt2" variant="text" close="true">${this._tl('cancel')}</sl-button>
        
      </modal-drawer>

    `;
  }

}

customElements.define('admin-language-edit', AdminLanguageEdit);