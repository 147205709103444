import { Session } from 'Utils';

//const hidden = ENV.match(/prod/) ? 'hidden' : '';
const hidden = 'hidden';

const properties = {
  name:'properties',
  title:'Propriétés <m-icon name="lock"></m-icon>',
  hidden:() => !Session.isBoss(),
  fields:[
    { component:'box-json' },
  ]
}

const definitions = {
  nafDivision:{
    name:'Division',
    micon:'folder_open',
    nodeTitle:['code', '-', 'title'],
    tabs:[
      {
        name:'general', 
        title:'Général',
        fields_hidden:[
          { name:'nodeType',  component:'sl-input',         label:'Type de noeud', sameDefaultValue: true, css: `${hidden}` },
        ],
        fields_lgs:[
          { name:'title',     component:'sl-input',         label:'Titre' },
        ]
      }
    ]
  },
  nafItem:{
    name:'Code APE',
    micon:'responsive_layout',
    nodeTitle:['code','-', 'title'],
    tabs:[
      {
        name:'general', 
        title:'Général',
        fields_hidden:[
          { name:'nodeType',  component:'sl-input',         label:'Type de noeud', sameDefaultValue: true, css: `${hidden}` },
        ],

        fields_lgs:[
          { name:'title',     component:'sl-input',         label:'Titre' },
        ]
      }
    ]
  }
}

for (const key in definitions) {
  definitions[key].tabs?.push({ ...properties })
}


export default definitions;